import React, { Component } from "react"
import Model from "./react-3d-viewer/model"
import PubSub from "pubsub-js"

class ThreeWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 150,
      height: 150,
      scrollDirection: "down",
      hover: false,
    }

    this._onScrollDirection = this._onScrollDirection.bind(this)
    this._onMouseEnter = this._onMouseEnter.bind(this)
    this._onMouseLeave = this._onMouseLeave.bind(this)
  }

  componentWillUnmount() {
    PubSub.unsubscribe("SCROLL_DIRECTION", this._onScrollDirection)
  }

  componentDidMount() {
    PubSub.subscribe("SCROLL_DIRECTION", this._onScrollDirection)
    setTimeout(() => {
      if (window.innerWidth <= 768) {
        this.setState({
          width: 250,
          height: 250,
        })
      } else {
        this.setState({
          width: 300,
          height: 300,
        })
      }
    }, 50)
  }

  _onScrollDirection(e, d) {
    this.setState({
      scrollDirection: d.direction,
    })
  }

  _onClick() {
    // console.log("_onClick")
    //PubSub.publish('OPEN_STUDIO')
    // document.querySelector('.vision-btn').click()
    PubSub.publish("AUDIO_PLAY_TOGGLE")
  }

  _onMouseEnter() {
    //PubSub.publish('ROCK_HOVER', {is:true})
    // document.querySelector('.vision-btn').classList.add("active")
    document.body.classList.remove("downscroll")

    this.setState({
      hover: true,
    })
  }

  _onMouseLeave() {
    //PubSub.publish('ROCK_HOVER', {is:false})
    // document.querySelector('.vision-btn').classList.remove("active")

    this.setState({
      hover: false,
    })
  }

  render() {
    const { width, height, scrollDirection, hover } = this.state
    //console.log(width, height)
    const { src, mtl, texPath } = this.props
    return (
      <div
        id="rock"
        onMouseEnter={() => this._onMouseEnter()}
        onMouseLeave={() => this._onMouseLeave()}
        // onClickCapture={() => this._onClick()}
        onClick={() => this._onClick()}
      >
        <Model
          width={width}
          height={height}
          position={{ x: 1.5, y: -0.5, z: 0 }}
          scale={{ x: 1.65, y: 1.65, z: 1.65 }}
          background="transparent"
          src={src}
          mtl={mtl}
          texPath={texPath}
          scrollDirection={scrollDirection}
          hover={hover}
        />
      </div>
    )
  }
}

export default ThreeWrapper
