import React, { Component } from "react"
//import { Link } from "gatsby"
// import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PubSub from "pubsub-js"
import LocalizedLink from "./LocalizedLink"
import Video from "./ui/video"

class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {
      className: "",
      imageFeaturedIndex: 0,
      imageFeatured: null,
      isVideo: false,
      video_file: "",
      column: 6,
    }
    this._onMouseEnter = this._onMouseEnter.bind(this)
    this._onHoverIn = this._onHoverIn.bind(this)

    this._onMouseLeave = this._onMouseLeave.bind(this)
    this._onHoverOut = this._onHoverOut.bind(this)

    this._onNotionStart = this._onNotionStart.bind(this)
    this._onNotion = this._onNotion.bind(this)
    this._onNotionEnd = this._onNotionEnd.bind(this)

    this._onLoad = this._onLoad.bind(this)
  }

  componentWillUnmount() {
    PubSub.unsubscribe("CARD_HOVER_IN", this._onHoverIn)
    PubSub.unsubscribe("CARD_HOVER_OUT", this._onHoverOut)

    PubSub.unsubscribe("INTRO_BEFORE_START", this._onNotionStart)
    PubSub.unsubscribe("NOTION", this._onNotion)
    PubSub.unsubscribe("INTRO_END", this._onNotionEnd)
  }
  componentDidMount() {
    PubSub.subscribe("CARD_HOVER_IN", this._onHoverIn)
    PubSub.subscribe("CARD_HOVER_OUT", this._onHoverOut)

    // PubSub.subscribe('INTRO_BEFORE_START', this._onNotionStart)
    // PubSub.subscribe('NOTION', this._onNotion)
    // PubSub.subscribe('INTRO_END', this._onNotionEnd)
    this.randomFeatured()
    // setTimeout(() => {}, 50)
  }

  _onNotionStart() {
    this.isIntro = true
  }
  _onNotion(e, d) {
    //console.log(d)
    if (d && document.querySelectorAll(".page-home .card")[d.index]) {
      //console.log(document.querySelectorAll(".page-home .card")[d.index])
      document.querySelectorAll(".page-home .card")[d.index].style.opacity = 1
    }
  }
  _onNotionEnd() {
    const cards = document.querySelectorAll(".page-home .card")
    cards.forEach((el) => {
      el.style.opacity = 1
    })
    this.isIntro = false
  }

  _onHoverIn(e, d) {
    const { slug } = this.props
    //console.log("-",d.slug, slug)
    let className = "else"
    if (slug === d.slug) {
      className = ""
    }
    this.setState({
      className: className,
    })
  }

  _onHoverOut() {
    this.setState({
      className: "",
    })
  }

  randomFeatured() {
    // console.log(this.props)
    const { data } = this.props
    const length = data.images_featured.length

    const imageFeaturedIndex = Math.floor(Math.random() * length)
    const isVideo =
      data.images_featured[imageFeaturedIndex].video_file &&
      data.images_featured[imageFeaturedIndex].video_file.url !== null
    const column = data.images_featured[imageFeaturedIndex].column

    // const isValid = this._isImageValide(
    //   data.images_featured[imageFeaturedIndex].image
    // )
    // console.log(isValid)
    // if (!isValid) {
    //   this.randomFeatured()
    //   return
    // }

    this.setState({
      imageFeatured: data.images_featured[imageFeaturedIndex],
      imageFeaturedIndex: imageFeaturedIndex,
      column: column,
      isVideo: isVideo,
    })
  }

  _isImageValide(images_featured) {
    return images_featured.localFile !== null
  }

  _onMouseEnter() {
    //console.log(this.props.slug)
    const { slug } = this.props
    PubSub.publish("CARD_HOVER_IN", { slug: slug })
  }

  _onMouseLeave() {
    PubSub.publish("CARD_HOVER_OUT")
  }

  _onLoad() {
    // return
    //console.log(this.isIntro)
    if (this.refs.card) {
      this.refs.card.style.opacity = 1
    } else {
      setTimeout(() => {
        this._onLoad()
      }, 100)
    }
  }

  // _isImageOk(imageFeatured){
  //     if(!imageFeatured)return false
  //     else if(!imageFeatured.image)return false
  //     else if(!imageFeatured.image.localFile)return false
  //     else return true
  //     // return imageFeatured && imageFeatured.image && imageFeatured.image.localFile
  // }

  render() {
    const { className } = this.state
    const { data, slug } = this.props
    const { imageFeatured, column, isVideo } = this.state
    // console.log(imageFeatured)
    if (!imageFeatured) return null
    // if(isVideo){
    //     console.log(isVideo)
    //     console.log(imageFeatured.video_file)
    // }
    // console.log(this._isImageOk(imageFeatured))
    return (
      <div ref="card" className={"card col-" + column + " " + className}>
        {/* <Link to={'/project/'+slug}> */}
        <LocalizedLink to={"/project/" + slug}>
          <figure
            onMouseEnter={() => this._onMouseEnter()}
            onMouseLeave={() => this._onMouseLeave()}
          >
            {!isVideo && (
              // <Img
              //   importance="high"
              //   //className={index === imageFeaturedIndex ? 'visible' : 'hidden'}
              //   fluid={imageFeatured.image.localFile.childImageSharp.fluid}
              //   onLoad={this._onLoad()}
              // />
              <GatsbyImage
                image={getImage(imageFeatured.image)}
                alt={data.title.text}
                onLoad={this._onLoad()}
                // width={imageFeatured.image.gatsbyImageData.width}
                // height={imageFeatured.image.gatsbyImageData.height}
              />
            )}
            {isVideo && imageFeatured && imageFeatured.image && (
              <Video
                url={imageFeatured.video_file.url}
                image={imageFeatured.url}
                onLoad={this._onLoad()}
              />
            )}
          </figure>
          <div className="card-text">
            <h2 className="fS">{data.chapo.text}</h2>
          </div>
          {/* </Link> */}
        </LocalizedLink>
      </div>
    )
  }
}

export default Card
