import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import PubSub from "pubsub-js"
import i18n from "../../config/i18n"
//import Burger from './ui/burger';
import LocalizedLink from "./LocalizedLink"
import HeaderBtn from "./header-btn"
import LanguageSwitcher from "./ui/language-switcher"
import AudioPlayer from "./ui/AudioPlayer"
import NewsMarquee from "./ui/NewsMarquee"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      infosLabel: "information",
      studioLabel: "vision",
      headerClass: "",
      notion: "",
      isAudioPlaying: false,
    }

    this.lastScrollTop = 0

    this._onOverlay = this._onOverlay.bind(this)
    // this._onStudio = this._onStudio.bind(this)
    // this._onInfos = this._onInfos.bind(this)
    this._onIntroStart = this._onIntroStart.bind(this)
    this._onIntroEnd = this._onIntroEnd.bind(this)
    this._onScreenSaverStart = this._onScreenSaverStart.bind(this)
    this._onNotion = this._onNotion.bind(this)
    this._onScreenSaverEnd = this._onScreenSaverEnd.bind(this)
    this._onRouteUpdate = this._onRouteUpdate.bind(this)
    this._onAudioPlaying = this._onAudioPlaying.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this._handleScroll)

    PubSub.subscribe(this.OPEN_OVERLAY)
    // PubSub.unsubscribe("IS_OPEN_STUDIO", this._onStudio)
    // PubSub.unsubscribe("IS_OPEN_INFOS", this._onInfos)

    PubSub.unsubscribe("INTRO_START", this._onIntroStart)
    PubSub.unsubscribe("INTRO_END", this._onIntroEnd)

    PubSub.unsubscribe("SCREENSAVER_START", this._onScreenSaverStart)
    PubSub.unsubscribe("SCREENSAVER_TICK", this._onNotion)
    PubSub.unsubscribe("SCREENSAVER_END", this._onScreenSaverEnd)

    PubSub.unsubscribe("ROUTE_UPDATE", this._onRouteUpdate)
    //PubSub.unsubscribe('ROCK_HOVER', this._onStudioHover)
    PubSub.unsubscribe("AUDIO_PLAYING", this._onAudioPlaying)
  }

  componentDidMount() {
    // console.log(window.navigator)
    var ua = window.navigator.userAgent
    if (ua.indexOf("MSIE") !== -1) {
      document.documentElement.classList.add("ie")
    }
    if (
      window.navigator.platform === "iPhone" &&
      ua.indexOf("Safari/6") !== -1
    ) {
      document.documentElement.classList.add("safari-6")
    }

    //(function(l){var i,s={touchend:function(){}};for(i in s)l.addEventListener(i,s)})(document); // sticky hover fix in iOS
    //console.log("header componentDidMount")
    window.addEventListener(`scroll`, this._handleScroll)

    this.OPEN_OVERLAY = PubSub.subscribe("OPEN_OVERLAY", this._onOverlay)
    // PubSub.subscribe("IS_OPEN_STUDIO", this._onStudio)
    // PubSub.subscribe("IS_OPEN_INFOS", this._onInfos)

    PubSub.subscribe("INTRO_START", this._onIntroStart)
    PubSub.subscribe("INTRO_END", this._onIntroEnd)

    PubSub.subscribe("SCREENSAVER_START", this._onScreenSaverStart)
    PubSub.subscribe("SCREENSAVER_TICK", this._onNotion)
    PubSub.subscribe("SCREENSAVER_END", this._onScreenSaverEnd)

    PubSub.subscribe("AUDIO_PLAYING", this._onAudioPlaying)

    const { lang, template } = this.props
    this.closeLabel = i18n[lang].close
    // console.log({ template })
    document.addEventListener("keyup", (e) => {
      const key = e.key || e.keyCode
      if (key === "Escape") {
        this._closeAllOverlays()
      }
    })

    this._headerTemplate()
    setTimeout(() => {
      PubSub.subscribe("ROUTE_UPDATE", this._onRouteUpdate)
      this._headerTemplate()
    }, 1000)
  }

  _headerTemplate() {
    const { template } = this.props
    // console.log({ template })
    if (template === "studio" || template === "infos") {
      // this.setState({
      //   headerClass: "is-" + template,
      //   notion: "",
      // })

      this.setState({
        headerClass: [...this.state.headerClass, "is-" + template],
      })
    }
  }

  _onRouteUpdate() {
    this._closeAllOverlays()
  }

  _closeAllOverlays() {
    //console.log("_closeAllOverlays")

    PubSub.publish("CLOSE_OVERLAY")
    // this.setState({
    //   headerClass: "",
    // })
    this.setState({
      // headerClass: [...this.state.headerClass, ""],
      headerClass: [],
    })
    document.documentElement.classList.remove("is-overlay")
  }

  _onOverlay(e, d) {
    const { target, is } = d
    // console.log({ is, target })
    if (is) {
      // this.setState({
      //   headerClass: "is-" + target,
      // })
      this.setState({
        headerClass: [...this.state.headerClass, "is-" + target],
      })
    } else {
      // this.setState({
      //   headerClass: "",
      // })
      this.setState({
        headerClass: [...this.state.headerClass, ""],
      })
    }
  }

  _handleScroll = () => {
    if (
      document.body.classList.contains("template-infos") ||
      document.body.classList.contains("template-studio")
    )
      return

    const st = window.pageYOffset || document.documentElement.scrollTop
    if (st > this.lastScrollTop) {
      document.body.classList.add("downscroll")
      PubSub.publish("SCROLL_DIRECTION", { direction: "down" })
    } else {
      document.body.classList.remove("downscroll")
      PubSub.publish("SCROLL_DIRECTION", { direction: "up" })
    }
    this.lastScrollTop = st <= 0 ? 0 : st
  }

  _onInfos(e, d) {
    const headerClass = d.is ? "is-infos" : ""
    // this.setState({
    //   headerClass: headerClass,
    // })
    this.setState({
      headerClass: [...this.state.headerClass, headerClass],
    })
  }
  _openInfos() {
    PubSub.publish("OPEN_INFOS")
  }

  _onStudio(e, d) {
    //const studioLabel = d.is ? this.closeLabel : "vision"
    const headerClass = d.is ? "is-studio" : ""
    // console.log("_onStudio", d)
    // this.setState({
    //   headerClass: headerClass,
    // })
    this.setState({
      headerClass: [...this.state.headerClass, headerClass],
    })
  }
  _openStudio() {
    PubSub.publish("OPEN_STUDIO")
  }

  //////////////////////////////////////////////
  _onIntroStart() {
    // this.setState({
    //   headerClass: "is-intro",
    // })
    this.setState({
      headerClass: [...this.state.headerClass, "is-intro"],
    })
  }

  _onIntroEnd() {
    // this.setState({
    //   headerClass: "",
    // })
    this.setState({
      headerClass: [...this.state.headerClass, ""],
    })
  }

  //////////////////////////////////////////////
  _onScreenSaverStart() {
    document.body.classList.remove("downscroll")
    //console.log("_onScreenSaverStart")
    // this.setState({
    //   headerClass: "is-screensaver",
    // })
    this.setState({
      headerClass: [...this.state.headerClass, "is-screensaver"],
    })
  }
  _onNotion(e, d) {
    //console.log("_onNotion", d.notion)
    this.setState({
      notion: d.notion,
    })
  }
  _onScreenSaverEnd() {
    console.log("_onScreenSaverEnd")
    // this._headerTemplate()
    // this.setState({
    //   headerClass: "",
    //   notion: "",
    // })
    const prev =
      this.state.headerClass.length > 2
        ? this.state.headerClass[this.state.headerClass.length - 2]
        : ""
    // console.log({ prev })
    this.setState({
      headerClass: [
        ...this.state.headerClass,
        prev !== "is-screensaver" ? prev : "",
      ],
      notion: "",
    })
  }

  _onAudioPlaying(e, d) {
    // console.log(d)
    this.setState({
      isAudioPlaying: d,
    })
  }
  //////////////////////////////////////////////

  render() {

    const { notion, isAudioPlaying } = this.state
    const headerClass =
      this.state.headerClass[this.state.headerClass.length - 1]
    // console.log(this.state.headerClass, headerClass)

    const { template, lang } = this.props
    // console.log("template", template, "headerClass", headerClass)
    const { site_title, site_description, audio, portfolio } = this.props.data
    // console.log(audio)
    const titleParts = site_title.text.split(" ")
    // console.log({ headerClass, template })
    return (
      <header className={headerClass + " -" + template}>
        {/* <div className="tabnum">01</div> */}

        <div className="inner">
          <div className="left">
            <h1 onClick={() => this._closeAllOverlays()}>
              <LocalizedLink to="/">
                <div className="title-parts">
                  <span>
                    {titleParts[0]} {titleParts[1]}
                  </span>
                  <span className="span-studio">Sensual City</span>
                  <span>{titleParts[2]}</span>
                </div>
              </LocalizedLink>
            </h1>
            {headerClass === "is-screensaver" && (
              <div className="description notions">{notion}</div>
            )}
            {headerClass === "is-intro" && (
              <div className="description ">{site_description.text}</div>
            )}
            {template === "coming-soon" && (
              <div className="description inline">coming soon</div>
            )}
          </div>

          <div className="right">
            <nav>
              <ul>
                {headerClass === "is-infos" && (
                  <li className="news-marquee-container">
                    <NewsMarquee lang={lang} />
                  </li>
                )}
                {template === 'home' && audio && (
                  <li
                    className={`${
                      isAudioPlaying ? "is-playing" : "is-not-playing"
                    }`}
                  >
                    <AudioPlayer
                      input={audio}
                      isHome={template === "home" && headerClass === ""}
                    />
                  </li>
                )}
                <li className="mr-f">
                  <LanguageSwitcher lang={lang} />
                </li>

                {(template === "legal" ||
                  template === "project" ||
                  template === "infos" ||
                  template === "studio") && (
                  <li>
                    <LocalizedLink to="/">
                      <span className="button">{i18n[lang].home}</span>
                    </LocalizedLink>
                  </li>
                )}

                {headerClass === "is-infos" && (
                  <li>
                    <a
                      target="_blank"
                      href={portfolio.url}
                      className="button"
                      rel="noopener, noreferrer"
                    >
                      portfolio
                    </a>
                  </li>
                )}

                {template !== "studio" && (
                  <li>
                    <HeaderBtn lang={lang} label="vision" target="studio" />
                  </li>
                )}

                {template !== "infos" && (
                  <li>
                    <HeaderBtn lang={lang} label="information" target="infos" />
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        dataFr: prismicGeneral(lang: { eq: "fr-fr" }) {
          data {
            site_title {
              text
            }
            site_description {
              text
            }
            portfolio {
              # name
              url
            }
            audio {
              # name
              url
            }
          }
        }
        dataEn: prismicGeneral(lang: { eq: "en-gb" }) {
          data {
            site_title {
              text
            }
            site_description {
              text
            }
            portfolio {
              # name
              url
            }
            audio {
              # name
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <Header
        lang={props.lang}
        template={props.template}
        data={props.lang === "fr-fr" ? data.dataFr.data : data.dataEn.data}
        // data={data.prismicGeneral.data}
      />
    )}
  />
)
