import React from "react"
import PubSub from "pubsub-js"
import { _wrapRootElement, _wrapPageElement } from "./wrapPageElement"

export const wrapRootElement = _wrapRootElement
export const wrapPageElement = _wrapPageElement

export const onRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  PubSub.publish("ROUTE_UPDATE")
}
