import React, { Component } from "react"
import { graphql } from "gatsby"
import PubSub from "pubsub-js"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import locales from "../../config/i18n"
//import Layout from "../components/layout"
// import Seo from "../components/seo-helmet"
//import ProjectGrid from "../components/project-grid"
import Project from "../components/project"
//import {fluidImage} from "../core/fragments"

class PageProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //descriptionClass: 'less',
      initialIndex: 0,
      projectIndex: 0,
      related: [],
      currentSlug: "",
    }
    this.lastScrollTop = 0
    //console.log(fluidImage)
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this._handleScroll)

    const { uid } = this.props.data.prismicProjects

    const { projects } = this.props.data.prismicHomepage.data
    projects.forEach((item, idx) => {
      if (item.project.uid === uid) {
        this.setState({
          initialIndex: idx,
          projectIndex: idx,
          currentSlug: item.project.uid,
        })
      }
    })
    // console.log(locales)
    // console.log(this.props.pageContext.locale)
    PubSub.subscribe("PROJECT_IN_VIEW", (e, d) => {
      if (this.state.currentSlug !== d.slug) {
        //console.log(this.props.pageContext)
        const { locale } = this.props.pageContext
        const localizedPath = locales[locale].default
          ? "/project/" + d.slug
          : "/" + locales[locale].path + "/project/" + d.slug
        //console.log(localizedPath)
        window.history.pushState({}, d.title, localizedPath)
        //this.state.currentSlug = d.slug
        this.setState({
          currentSlug: d.slug,
        })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this._handleScroll)
  }

  _handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this._update())
    }
  }

  _update() {
    //this._scrollDirection();

    //console.log(this.lastScrollTop)
    const distanceToBottom =
      document.body.scrollHeight - (window.innerHeight + window.scrollY)
    // console.log({ distanceToBottom })
    if (distanceToBottom < 100) {
      this._loadNextProject()
    }
    this.ticking = false
  }
  // _scrollDirection(){
  //   const st = window.pageYOffset || document.documentElement.scrollTop;
  //   if (st > this.lastScrollTop){
  //     document.body.classList.add("downscroll")
  //   } else {
  //     document.body.classList.remove("downscroll")
  //   }
  //   this.lastScrollTop = st <= 0 ? 0 : st;
  // }

  _loadNextProject() {
    const { projects } = this.props.data.prismicHomepage.data

    // if(projects[this.state.projectIndex + 1].project.uid
    //   === this.props.data.prismicProjects.uid){
    //     window.removeEventListener(`scroll`, this._handleScroll)
    //     return
    // }
    // console.log(this.state.initialIndex, this.state.projectIndex)
    const { initialIndex } = this.state
    let nextIndex = 0
    if (this.state.projectIndex + 1 < projects.length) {
      nextIndex = this.state.projectIndex + 1
    } else {
      nextIndex = 0
    }
    if (nextIndex === initialIndex) nextIndex = null
    //console.log(nextIndex, projects.length)
    this.setState(
      {
        //projectIndex: this.state.projectIndex + 1
        projectIndex: nextIndex,
      },
      () => {
        if (projects[this.state.projectIndex]) {
          const slug = projects[this.state.projectIndex].project.uid
          const project =
            projects[this.state.projectIndex].project.document.data

          this.setState({
            related: [
              ...this.state.related,
              {
                slug: slug,
                project: project,
              },
            ],
          })
        } else {
          //this._reset()
          window.removeEventListener(`scroll`, this._handleScroll)
        }
      }
    )
  }

  // _reset(){
  //   this.setState({
  //     projectIndex: -1
  //   }, () => {
  //     this._loadNextProject()
  //   })
  // }

  render() {
    const { isLoading, related } = this.state
    const { locale } = this.props.pageContext
    // console.log(this.props)
    const slug = this.props.data.prismicProjects.uid
    const { title, chapo, texte, fiche_technique, images_featured, images } =
      this.props.data.prismicProjects.data
    //console.log(this.props.pageContext)

    return (
      <div className="page-project">
        <Project
          locale={locale}
          slug={slug}
          title={title}
          chapo={chapo}
          texte={texte}
          images_featured={images_featured}
          images={images}
          fiche_technique={fiche_technique}
        />
        <div className="related">
          {related.map((item, key) => {
            return (
              <Project
                key={key}
                locale={locale}
                slug={item.slug}
                title={item.project.title}
                chapo={item.project.chapo}
                texte={item.project.texte}
                images_featured={item.project.images_featured}
                images={item.project.images}
                fiche_technique={item.project.fiche_technique}
              />
            )
          })}
          {isLoading && <div>Loading...</div>}
        </div>
      </div>
    )
  }
}

export default withPrismicPreview(PageProject)

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!, $locale: String!) {
    prismicProjects(uid: { eq: $uid }, lang: { eq: $locale }) {
      _previewable
      uid
      data {
        ...project
      }
    }

    prismicHomepage(lang: { eq: $locale }) {
      data {
        projects {
          project {
            uid
            document {
              ... on PrismicProjects {
                data {
                  ...project
                }
              }
            }
          }
        }
      }
    }
  }
`
