/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react"

import { Layout } from "./src/components/layout"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import { _linkResolver } from "./src/core/utils"
import PageHome from "./src/templates/page-home"
import PageProject from "./src/templates/page-project"

// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

const _wrapRootElement = ({ element }) => {
  return (
    <>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver: _linkResolver,
            // componentResolver: componentResolverFromMap({
            //   homepage: PageHome,
            //   projects: PageProject,
            //   //   images: TemplateImages,
            // }),
            componentResolver: {
              homepage: PageHome,
              projects: PageProject,
              //   images: TemplateImages,
            },
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </>
  )
}

// export default wrapPageElement
const _wrapPageElement = ({ element, props }) => {
  //console.log(props)
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <Layout template={props.pageContext.template} {...props}>
      {element}
    </Layout>
  )
}
// export default wrapPageElement
export { _wrapRootElement, _wrapPageElement }
