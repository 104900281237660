import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import ExcerptToTexte from "./ui/ExcerptToTexte"
import useLayoutContext from "./layout"
// import Img from "gatsby-image"
// import ExcerptToTexte from "./ui/ExcerptToTexte"
// import ImageFluid from "./ui/image-fluid"
// import { LocaleContext } from "./layout"

const PublicationCard = ({
  title,
  infos,
  link,
  link_label,
  excerpt,
  texte,
  image,
  excerptMaxLength,
  input,
}) => {
  // console.log(LocaleContext)
  // const lang =

  return (
    <div className="publication-card fXS">
      <div className="top">
        {image && image.gatsbyImageData && (
          <div className="ar-3:4">
            <figure>
              <img
                src={image.gatsbyImageData.images.fallback.src}
                srcSet={image.gatsbyImageData.images.fallback.srcSet}
                sizes={image.gatsbyImageData.images.fallback.sizes}
                alt={image.alt || title.text}
                width={image.gatsbyImageData.width}
                height={image.gatsbyImageData.height}
                loading="lazy"
              />
            </figure>
          </div>
        )}

        <div>
          <div className="header ">
            <h3 className="fXS">{title.text}</h3>
            {infos && (
              <div className="publication-infos">
                <div
                  dangerouslySetInnerHTML={{
                    __html: infos,
                  }}
                />
              </div>
            )}
          </div>

          {/* <div
            className="texte"
            dangerouslySetInnerHTML={{
              __html: texte,
            }}
          /> */}
          {/* {excerpt} */}
          <ExcerptToTexte
            text={texte}
            html={texte}
            maxWords={excerptMaxLength}
          />
        </div>
      </div>

      {link && (
        <div className="cta">
          <a href={link} className="button" target="_blank">
            {link_label ? link_label : "order"}
          </a>
        </div>
      )}
    </div>
  )
}

export default PublicationCard
