exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-home-js": () => import("./../../../src/templates/page-home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-page-infos-js": () => import("./../../../src/templates/page-infos.js" /* webpackChunkName: "component---src-templates-page-infos-js" */),
  "component---src-templates-page-legal-notice-js": () => import("./../../../src/templates/page-legal-notice.js" /* webpackChunkName: "component---src-templates-page-legal-notice-js" */),
  "component---src-templates-page-project-js": () => import("./../../../src/templates/page-project.js" /* webpackChunkName: "component---src-templates-page-project-js" */),
  "component---src-templates-page-studio-js": () => import("./../../../src/templates/page-studio.js" /* webpackChunkName: "component---src-templates-page-studio-js" */)
}

