import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import PubSub from "pubsub-js"
import i18n from "../../config/i18n"
import scrollIntoView from "scroll-into-view"
import PublicationCard from "./PublicationCard"

class Studio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }

    this._toggle = this._toggle.bind(this)
    this._close = this._close.bind(this)
  }

  componentWillUnmount() {
    // console.log("component Will Unmount",this)
    PubSub.unsubscribe(this.OPEN_OVERLAY)
    PubSub.unsubscribe(this.CLOSE_OVERLAY)

    window.removeEventListener("wheel", this._onWheel)
  }

  componentDidMount() {
    // console.log("componentDidMount",this)
    this.OPEN_OVERLAY = PubSub.subscribe("OPEN_OVERLAY", this._toggle)
    this.CLOSE_OVERLAY = PubSub.subscribe("CLOSE_OVERLAY", this._close)

    this.topOffset = document
      .querySelector("header")
      .getBoundingClientRect().height

    window.addEventListener("wheel", this._onWheel)

    if ("ontouchstart" in window) {
      this.isTouch = true
    } else {
      this.isTouch = false
    }
  }

  _onWheel() {
    const elems = document.querySelectorAll(".button.active")
    ;[].forEach.call(elems, function (el) {
      el.classList.remove("active")
    })
  }

  _toggle(e, d) {
    // console.log(d)
    let active = false
    document.documentElement.classList.remove("is-overlay")

    if (d.target === "studio" && d.is) {
      active = true
      this.refs.overlay.classList.remove("bottom")
    } else {
      this.refs.overlay.classList.add("bottom")
    }

    this.setState(
      {
        active: active,
      },
      () => {
        if (active) {
          document.documentElement.classList.add("is-overlay")
        }
      }
    )
  }

  _close() {
    //document.body.classList.remove("is-overlay")
    if (!this.refs.overlay) return
    this.refs.overlay.classList.add("bottom")
    this.setState({
      active: false,
    })
  }

  _onTabClick(e, index) {
    const elems = document.querySelectorAll(".button.active")
    ;[].forEach.call(elems, function (el) {
      el.classList.remove("active")
    })
    e.target.classList.add("active")

    const tabMarge = document
      .querySelector(".tab")
      .getBoundingClientRect().height

    const target = document.getElementById("notion-" + index)

    let topOffset = 0
    if (this.isTouch) {
      topOffset = this.topOffset + 15
    } else {
      topOffset = this.topOffset + tabMarge + 46
    }

    scrollIntoView(
      target,
      {
        align: {
          top: 0,
          topOffset: topOffset,
        },
      },
      () => {}
    )
  }

  render() {
    const { className, locale } = this.props

    const { overlay } = this.props

    const {
      texte,
      papers_description,
      papers,
      publications_description,
      publications,
      studio_archive,
    } = this.props.data

    console.log(this.props.data)
    // console.log(papers)

    const overlayClass = overlay ? "overlay" : ""

    return (
      <div className={"studio " + overlayClass + " " + className} ref="overlay">
        <div
          className="texte"
          dangerouslySetInnerHTML={{ __html: texte.html }}
        />

        {/*<div className="notions ">
          <ul>
            {notions.map((notion, i) => (
              <li key={i}>
                <span>{notion.title1.text}</span> : {notion.texte1.text}
              </li>
            ))}
          </ul>

           <div className="link">
            {i18n[locale].vision_link_label}{" "}
            <a
              target="_blank"
              className="button"
              rel="noopener noreferrer"
              href="http://search.sensual-city.com"
            >
              Sensual City{" "}
            </a>{" "}
            archive
          </div>
        </div>*/}

        {papers_description && (
          <div
            className="texte"
            dangerouslySetInnerHTML={{ __html: papers_description.html }}
          />
        )}

        {/*
        title1, infos, link, link_label, texte1, image
        */}
        {papers && (
          <div className="papers">
            {/* <pre>{JSON.stringify(papers, null, 2)}</pre> */}
            <div className="grid">
              {papers.map((item, key) => (
                <div className="col-xs-12- col-md-3-" key={key}>
                  <PublicationCard
                    title={item.title.text}
                    excerpt={item.texte.text}
                    texte={item.texte.text}
                    infos=""
                    image={item.image}
                    link_label={item.link_label.text}
                    link={item.link.url}
                    input={item}
                    excerptMaxLength={135}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {/*
        title1, infos, link, link_label, texte1, image
        */}
        <div className="publications">
          {/* <h2>{i18n[locale].publications_label}</h2> */}
          {publications_description && (
            <div
              className="texte"
              dangerouslySetInnerHTML={{
                __html: publications_description.html,
              }}
            />
          )}
          <div className="row">
            {publications.map((item, key) => (
              <div className="col-xs-12 col-md-4" key={key}>
                <PublicationCard
                  title={item.title1.text}
                  texte={item.texte1.text}
                  infos={item.infos.html}
                  image={item.image}
                  link_label={item.link_label.text}
                  link={item.link.url}
                  input={item}
                  excerptMaxLength={145}
                />
              </div>
            ))}
          </div>
        </div>

        {studio_archive && (
          <div className="link">
            <div
              className="texte"
              dangerouslySetInnerHTML={{ __html: studio_archive.html }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        en: prismicStudio(lang: { eq: "en-gb" }) {
          ...studio
        }
        fr: prismicStudio(lang: { eq: "fr-fr" }) {
          ...studio
        }
      }
    `}
    render={(data) => (
      <Studio
        //className={props.className}
        locale={props.locale}
        //overlay={}
        {...props}
        data={props.locale === "fr-fr" ? data.fr.data : data.en.data}
      />
    )}
  />
)
