import React, { useContext, useEffect } from "react"
import Helmet from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import Infos from "./infos"
import Studio from "./studio"
import ScreenSaver from "./ui/screensaver"

import i18n from "../../config/i18n"
import NewsMarquee from "./ui/NewsMarquee"
import { subscribe, unsubscribe } from "pubsub-js"
// import WrapperContext from "../context/WrapperContext"

require("../styles/index.scss")

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale, template } }) => {
  useEffect(() => {
    _format()
    window.addEventListener("resize", _format)
    const token = subscribe("OPEN_OVERLAY", (e, d) => {
      if (d.is) _format()
    })

    return () => {
      window.removeEventListener("resize", _format)
      unsubscribe(token)
    }
  }, [])

  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    )

    const header = document.querySelector("header")

    const headerBounding = header.getBoundingClientRect()
    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )

    // const footerBounding = document
    //   .querySelector("footer")
    //   .getBoundingClientRect()

    // document.documentElement.style.setProperty(
    //   "--footer-height",
    //   footerBounding.height + "px"
    // )
  }

  return (
    <>
      <Helmet>
        <body className={"template-" + template} />
      </Helmet>
      <LocaleContext.Provider value={{ locale, i18n }}>
        <div id="page">
          {/* {locale} */}
          {locale && (
            <>
              <Header lang={locale} template={template} />
              {/* <NewsMarquee lang={locale} /> */}
              <main>
                {children}

                {template !== "infos" && (
                  <Infos className="bottom" locale={locale} overlay />
                )}
                {template !== "studio" && (
                  <Studio className="bottom" locale={locale} overlay />
                )}
              </main>
              {template === "home" && <Footer lang={locale} />}
            </>
          )}
          {!locale && (
            <>
              <Header template={template} lang="en-gb" />
              <NewsMarquee lang={locale} />
              <main>{children}</main>
            </>
          )}

          <ScreenSaver />
        </div>
      </LocaleContext.Provider>
    </>
  )
}

export { LocaleContext, Layout }

export default function useLayoutContext() {
  return useContext(LocaleContext)
}
