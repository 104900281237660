import React, { Component } from "react"
//import { Link } from "gatsby"
import cookie from "react-cookies"
import LocalizedLink from "./LocalizedLink"
import PubSub from "pubsub-js"
import i18n from "../../config/i18n"
// import LanguageSwitcher from "./ui/language-switcher"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _acceptedCookies: false,
      footerClass: "bottom",
    }

    this._onScrollDirection = this._onScrollDirection.bind(this)
    this._acceptCookies = this._acceptCookies.bind(this)
  }

  componentWillUnmount() {
    PubSub.unsubscribe("SCROLL_DIRECTION", this._onScrollDirection)
  }

  componentWillMount() {
    const _acceptedCookies = cookie.load("_acceptedCookies")
    this.setState({
      _acceptedCookies: _acceptedCookies,
    })
  }

  componentDidMount() {
    //PubSub.subscribe("SCROLL_DIRECTION", this._onScrollDirection)
  }

  _onScrollDirection(e, d) {
    const footerClass = d.direction === "up" ? "" : "bottom"
    this.setState({
      footerClass: footerClass,
    })
  }

  _acceptCookies() {
    cookie.save("_acceptedCookies", true, { path: "/" })
    this.setState({
      _acceptedCookies: true,
    })
  }

  render() {
    const { footerClass, _acceptedCookies } = this.state
    //console.log(_acceptedCookies)
    const { lang } = this.props
    //console.log(lang)
    return (
      <footer className={"fXXS " + footerClass}>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {_acceptedCookies === undefined && (
              <div className="left">
                <span>{i18n[lang].cookiesMessage}</span>
                <ul>
                  <li>
                    <button
                      className="button accept-cookies"
                      onClick={() => this._acceptCookies()}
                    >
                      {i18n[lang].accept}
                    </button>
                  </li>
                  <li>
                    <LocalizedLink to="/legal-notices" className="u">
                      {i18n[lang].learnMore}
                    </LocalizedLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="text-right">
              {/* <LanguageSwitcher lang={lang} /> */}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
