import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PubSub from "pubsub-js"
// import Img from "gatsby-image"
import i18n from "../../config/i18n"
import ImageFluid from "./ui/image-fluid"
//import SEO from "./SEO"

import Mailchimp from "./ui/mailchimp"
// import Awards from './infos-awards';
// import Exhibitions from './infos-exhibitions';

class Infos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }

    this._toggle = this._toggle.bind(this)
    this._close = this._close.bind(this)
  }

  componentWillUnmount() {
    // console.log("component Will Unmount",this)
    PubSub.unsubscribe(this.OPEN_OVERLAY)
    PubSub.unsubscribe(this.CLOSE_OVERLAY)
  }

  componentDidMount() {
    // console.log("componentDidMount",this)
    this.OPEN_OVERLAY = PubSub.subscribe("OPEN_OVERLAY", this._toggle)
    this.CLOSE_OVERLAY = PubSub.subscribe("CLOSE_OVERLAY", this._close)
  }

  _toggle(e, d) {
    // console.log(d)
    let active = false
    document.documentElement.classList.remove("is-overlay")

    if (d.target === "infos" && d.is) {
      active = true
      this.refs.overlay.classList.remove("bottom")
    } else {
      this.refs.overlay.classList.add("bottom")
    }

    this.setState(
      {
        active: active,
      },
      () => {
        if (active) {
          document.documentElement.classList.add("is-overlay")
        }
      }
    )
  }

  _close() {
    this.refs.overlay.classList.add("bottom")
    this.setState({
      active: false,
    })
  }

  render() {
    //console.log(this.props)
    const { className, locale } = this.props

    const { overlay } = this.props

    const {
      title,
      texte,
      image,
      bios,
      contacts,
      social,
      textes_columns,
      credits,
    } = this.props.data
    // console.log(this.props)

    const overlayClass = overlay ? "overlay" : ""

    return (
      <div
        className={"infos " + overlayClass + " fS " + className}
        ref="overlay"
      >
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <aside>
              <ul className="contacts">
                {contacts.map((li, key) => {
                  return (
                    <li key={key}>
                      <h2>{li.title1.text}</h2>
                      <address
                        dangerouslySetInnerHTML={{
                          __html: li.address.html,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: li.contact.html,
                        }}
                      />
                    </li>
                  )
                })}
              </ul>

              <ul className="social">
                {social.map((li, key) => {
                  return (
                    <li key={key}>
                      <a href={li.url.url} target="_blank">
                        <i className={"fS icon icon-" + li.service.text} />
                      </a>
                      {/* <Link
                                                target={li.url.target}
                                                to={li.url.url}
                                            >
                                            </Link> */}
                    </li>
                  )
                })}
              </ul>

              <Mailchimp
                action="https://agencejfa.us20.list-manage.com/subscribe/post?u=c59b5c96b9d8e4c1943d4786b&amp;id=4047e9357a"
                fields={[
                  {
                    name: "EMAIL",
                    placeholder: "newsletter",
                    type: "email",
                    required: true,
                  },
                ]}
                messages={{
                  sending: i18n[locale].sending,
                  success: i18n[locale].success,
                  error: i18n[locale].error,
                  empty: i18n[locale].empty,
                  duplicate: i18n[locale].duplicate,
                  button: "ok",
                }}
                className="mailchimp"
              />

              <div className="credits">
                <h2>{i18n[locale].credits}</h2>
                <div className="credits-content">
                  <div>
                    Design{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="http://olivierlebrun.fr"
                    >
                      Olivier Lebrun
                    </a>
                  </div>
                  <div>
                    Code{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://ahmedghazi.com"
                    >
                      Ahmed Ghazi
                    </a>
                  </div>
                  <div>
                    Photos {i18n[locale].by} {credits.text}
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <div className="col-xs-12 col-md-9">
            <div
              className="texte "
              dangerouslySetInnerHTML={{ __html: texte.html }}
            />

            <div className="team">
              {/* <Img fluid={image.localFile.childImageSharp.fluid} /> */}
              {/* <Img fluid={image.fluid} /> */}
              <ImageFluid data={image} title={"team"} />
            </div>

            <div className="about">
              <h2>{i18n[locale].about}</h2>
              {bios.map((bio, i) => (
                <div
                  key={i}
                  className="texte no-indent"
                  dangerouslySetInnerHTML={{
                    __html: bio.texte1.html,
                  }}
                />
              ))}
            </div>

            <div className="grid-masonry">
              {textes_columns.map((col, idx) => (
                <div key={idx} className="col textes_columns">
                  <h2>{col.title1.text}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: col.texte1.html,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        infosEn: prismicInfos(lang: { eq: "en-gb" }) {
          ...infos
        }
        infosFr: prismicInfos(lang: { eq: "fr-fr" }) {
          ...infos
        }
      }
    `}
    render={(data) => (
      <Infos
        // className={props.className}
        // //data={data.prismicInfos.data}
        // locale={props.lang}
        {...props}
        data={props.locale === "fr-fr" ? data.infosFr.data : data.infosEn.data}
      />
    )}
  />
)
