import React, { Component } from "react"
import Card from "./project-card"

class ProjectGrid extends Component {
  render() {
    const { data, title, desc } = this.props

    return (
      <div className="grid">
        {data.map((item, index) => (
          <Card data={item} title={title} desc={desc} key={index} />
        ))}
      </div>
    )
  }
}

export default ProjectGrid
