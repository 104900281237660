import React, { Component } from 'react';

class Star extends Component {
    render() {
        const {
            total,
            index
        } = this.props

        //if(!total)return (null)
        
        const degreeGap = 180 / total
        const arr = Array(total).fill().map((_, idx) => idx)
        //console.log("-",index, total)
        return (
            <div className={"star "}>
                {arr.map((item, key) => {
                    //console.log(key)
                    const barClass = key <= index ? 'visible' : '';
                    return (
                        <div 
                        key={key}
                        className={"bar "+barClass}
                        style={{
                            transform: 'translate(-50%, -50%) rotate('+(key*degreeGap)+'deg)'
                        }}
                        ></div>
                    )
                }
                )}
            </div>
        );
    }
}

export default Star;