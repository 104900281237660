import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import cookie from "react-cookies"
// import i18n from '../../../config/i18n'

const locales = require("../../../config/i18n")
//import classNames from "classnames"
//import { translate } from "react-i18next"

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props)
    // const { i18n } = this.props
    this.state = {
      //language: i18n.language,
      nav: [],
    }
    //console.log(locales)
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  componentWillMount() {}

  componentDidMount() {
    Object.values(locales).forEach((locale) => {
      //console.log(locale)
      const url = this._getLocalizedUrl(locale)
      ///console.log(url)
      const nav = this.state.nav
      const li = {
        url: url,
        label: locale.label,
        locale: locale.locale,
      }
      nav.push(li)
      this.setState({
        nav: nav,
      })
    })

    //this._browserLang()
  }

  _getLocalizedUrl(locale) {
    let { pathname } = window.location
    let url = ""
    if (pathname.indexOf("project") > -1) {
      const rm = "/" + locale.path + "/"
      pathname = pathname.replace(rm, "/")
      //console.log(locale)
      url = locale.default // === "en"
        ? pathname
        : locale.path + pathname
    } else {
      url = locale.default ? "/" : "/" + locale.path
    }
    return url
  }

  _browserLang() {
    const _userLang = cookie.load("_userLang")
    if (!_userLang) {
      if (navigator) {
        const userLang = navigator.language || navigator.userLanguage
        cookie.save("_userLang", userLang, { path: "/" })
        if (userLang !== this.props.lang) {
          //redirect
          const locale = locales[userLang.toLowerCase()]
          const url = this._getLocalizedUrl(locale)
          navigate(url)
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ language: nextProps.i18n.language })
  }

  handleChangeLanguage(lng) {
    // const { i18n } = this.props
    // i18n.changeLanguage(lng)
  }

  renderLanguages() {
    // const langKeys = Object.keys(locales)
    //console.log(this.props)
    const { nav } = this.state
    const { lang } = this.props
    const alternateLang = nav.filter((el) => el.locale !== lang)

    const languages = alternateLang.map((li, key) => {
      const _liClassName = li.locale === lang ? "active" : ""
      //console.log(_liClassName, li.locale, lang)
      return (
        <div key={key} className="language-switcher-btn">
          <Link className={_liClassName + " button button-rounded"} to={li.url}>
            {li.label}
          </Link>
        </div>
      )
    })

    return languages
  }

  render() {
    //console.log(Object.keys(locales))
    // const {lang} = this.props
    return <div className="language-switcher">{this.renderLanguages()}</div>
  }
}

//export default translate("LanguageSwitcher")(LanguageSwitcher)
export default LanguageSwitcher
