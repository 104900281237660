import React, { Component } from "react"
//import {debounce} from 'throttle-debounce';
import PubSub from "pubsub-js"
import ProjectGrid from "./project-grid"
//import Seo from "../components/seo"
//import { SEO } from './SEO/index'
import i18n from "../../config/i18n"
import Seo from "./seo-helmet"

class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      descriptionClass: "less",
    }
    this._toggleTexte = this._toggleTexte.bind(this)
    this._handleScroll = this._handleScroll.bind(this)
    //this._handleScroll = debounce(250, this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll)
  }

  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll)
  }

  _handleScroll() {
    // assuming you're using https://babeljs.io/docs/plugins/transform-class-properties/
    // console.log('Debounced scroll event', this.refs.project);
    const isVisible = this.__checkVisible(this.refs.project)
    // console.log('isVisible', isVisible);
    // console.log(this.props.slug)
    if (isVisible) {
      //console.log(this.props.slug)
      PubSub.publish("PROJECT_IN_VIEW", {
        slug: this.props.slug,
        title: this.props.title,
      })
    }
  }

  __checkVisible(elm) {
    if (!elm) return false

    var rect = elm.getBoundingClientRect()
    return rect.top < 0 && rect.bottom > 0
    //var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    //return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    // var bounding = elm.getBoundingClientRect();
    // return (
    //     bounding.top >= 0 &&
    //     bounding.left >= 0 &&
    //     bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //     bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    // );
  }

  _toggleTexte() {
    this.refs.description.classList.toggle("less")
    this.refs.texte.classList.toggle("hidden")
  }

  render() {
    const {
      locale,
      title,
      chapo,
      texte,
      images_featured,
      images,
      fiche_technique,
    } = this.props
    //console.log(fiche_technique)
    const _images = images_featured.concat(images)
    //console.log(chapo.text)
    return (
      <div className="project" ref="project">
        <Seo
          title={title.text}
          desc={chapo.text}
          article
          articleBanner={_images[0].image.url}
          // pathname={location.pathname}
          locale={locale}
        />
        <div className="projet-header">
          <h2>{title.text}</h2>
          <div ref="description" className="description less">
            <div className="chapo">{chapo.text}</div>
            <div onClick={() => this._toggleTexte()} className="btn-more">
              ({i18n[locale].more})
            </div>
            <div onClick={() => this._toggleTexte()} className="btn-less">
              ({i18n[locale].less})
            </div>
            <div
              ref="texte"
              className="texte hidden"
              dangerouslySetInnerHTML={{ __html: texte.html }}
            />
          </div>
        </div>

        <ProjectGrid data={_images} title={title.text} desc={chapo.text} />
        <div className="fiche-tech fXS">
          <ul className="fiche-tech--list">
            {fiche_technique.map((li, key) => (
              <li key={key}>
                <span>{li.label.text}</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    // __html: li.value.html.replace("h1", "span"),
                    __html: li.value.html,
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default Project
