import React, { useState } from "react"
import i18n from "../../../config/i18n"
import useLayoutContext from "../layout"

const ExcerptToTexte = ({ text, html, maxWords = 135 }) => {
  // const [excerpt, setExcerpt] = useState()
  const [collapsed, setCollapsed] = useState(true)
  // console.log(i18n)
  const { locale } = useLayoutContext()
  console.log(locale)

  const _truncate = () => {
    const explode = text.split("")
    let string = explode.splice(0, maxWords).join("")
    string += `... <button class='btn-more'>(${i18n[locale].more})</button>`
    // console.log(explode)
    return string
    // const
  }

  return (
    <div
      className="excerpt-to-texte"
      role="button"
      tabIndex="0"
      onClick={() => setCollapsed(!collapsed)}
      onKeyDown={() => setCollapsed(!collapsed)}
    >
      {collapsed ? (
        <p
          className="curp"
          dangerouslySetInnerHTML={{
            __html: _truncate(),
          }}
        ></p>
      ) : (
        <>
          <div
            className="texte"
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
          <button className="btn-less" onClick={() => setCollapsed(true)}>
            ({i18n[locale].less})
          </button>
        </>
      )}
    </div>
  )
}

export default ExcerptToTexte
