import React, { Component } from "react"
import PubSub from "pubsub-js"
import locales from "../../config/i18n"
import { LocaleContext } from "./layout"

class HeaderBtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
    this._onClick = this._onClick.bind(this)
    this._onRouteUpdate = this._onRouteUpdate.bind(this)
    this._reset = this._reset.bind(this)

    this.history = []
  }

  componentWillMount() {}

  componentWillUnmount() {
    PubSub.unsubscribe("ROUTE_UPDATE", this._onRouteUpdate)
    PubSub.subscribe("CLOSE_OVERLAY", this._reset)
  }

  componentDidMount() {
    const { lang } = this.props || "en-gb"
    // console.log(lang)
    this.closeLabel = locales[lang].close || "close"

    const { target } = this.props

    PubSub.subscribe("ROUTE_UPDATE", this._onRouteUpdate)
    PubSub.subscribe("CLOSE_OVERLAY", this._reset)
    PubSub.subscribe("HEADER_BTN", (e, d) => {
      if (d.target !== target) {
        this.setState({
          active: false,
        })
      }
    })
  }

  _onRouteUpdate() {
    this.history.push({
      title: document.title,
      url: window.location.href,
    })
    this.setState({
      active: false,
    })
  }

  _onClick() {
    //console.log("click")
    const {
      target,
      //label
    } = this.props
    //const _target = target.toUpperCase()

    PubSub.publish("HEADER_BTN", { target: target })
    // PubSub.publish("OPEN_"+_target)

    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        // console.log(target)
        PubSub.publishSync("OPEN_OVERLAY", {
          target: target,
          is: this.state.active,
        })
        this._updateUrl()
      }
    )
  }

  _updateUrl() {
    const {
      // target,
      label,
    } = this.props

    const { active } = this.state

    if (active) {
      const url = this._localizedUrl(label)
      // window.history.pushState({}, label, "/"+label)
      window.history.pushState({}, label, url)
    } else {
      const { title, url } = this.history[this.history.length - 1]
      window.history.pushState({}, title, url)

      if (url.indexOf("vision") > -1) {
        PubSub.publish("IS_OPEN_STUDIO", { is: true })
      }
    }
  }

  _localizedUrl(path) {
    const { lang } = this.props
    const url = locales[lang].default
      ? `/${path}`
      : `/${locales[lang].path}/${path}`

    return url
  }

  _reset() {
    this.setState({
      active: false,
    })
  }

  render() {
    const { active } = this.state

    const { label } = this.props

    const _label = active ? this.closeLabel : label
    return (
      // <LocaleContext.Consumer>
      //     <button
      //     onClick={this._onClick}
      //     className={"button "+label+"-btn"}>
      //         {_label}
      //     </button>
      // </LocaleContext.Consumer>

      <LocaleContext.Consumer>
        {(props) => (
          <button
            {...props}
            onClick={this._onClick}
            className={"button " + label + "-btn"}
          >
            {_label}
          </button>
        )}
      </LocaleContext.Consumer>
    )
  }
}

export default HeaderBtn
