//const website = require('./website')

module.exports = {
  'fr-fr': {
    default: false,
    path: 'fr',
    locale: 'fr-fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    label: 'Fr',
    cookiesMessage: 'Ce site utilise les cookies',
    accept: "J'accepte",
    learnMore: 'En savoir plus',
    more: 'lire la suite',
    less: 'réduire',
    information: 'information',
    vision: 'vision',
    vision_themes: 'Thèmes et idées qui guident notre travail',
    vision_link_label: 'Pour en savoir plus sur le contenu de notre recherche, veuillez visiter notre',
    publications_label: 'Une sélection de publications éditées par le studio',
    home: 'accueil',
    lang_default_label: 'Langue par défaut',
    about: 'À propos',
    close: 'fermer',
    credits: 'crédits',
    by: 'par',
    success: "Merci de vous être abonné!",
    error: "Une erreur interne inattendue s'est produite.",
    empty: "Vous devez écrire un e-mail.",
    duplicate: "Trop de tentatives d'abonnement pour cette adresse email"
  },
  'en-gb': {
    default: true,
    path: 'en',
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_US',
    label: 'En',
    cookiesMessage: 'This website uses cookies',
    accept: 'Accept',
    learnMore: 'Learn about it',
    more: 'read more',
    less: 'less',
    information: 'information',
    vision: 'vision',
    vision_themes: 'Themes and ideas that inform our work',
    vision_link_label: 'To learn more about our research content, please visit our',
    publications_label: 'A selection of publications that’ve been edited by the studio',
    home: 'home',
    lang_default_label: 'Language by default',
    about: 'About',
    close: 'close',
    credits: 'credits',
    by: 'by',
    success: "Thank you for subscribing!",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address"
  }
}