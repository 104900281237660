import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
//import Seo from "../components/seo"
import Helmet from "react-helmet"
import PubSub from "pubsub-js"
import cookie from "react-cookies"
import Seo from "../components/seo-helmet"

//import Layout from "../components/layout"
import HomeGrid from "../components/home-grid"
import ThreeWrapper from "../components/ui/3d/ThreeWrapper"
import Star from "../components/ui/star"
// import Menu from "../components/ui/menu"
//import Intro from "../components/intro"

// import favicon from '../images/favicon.png';

export const pageQuery = graphql`
  query homePageByLocale($locale: String!) {
    general: prismicGeneral {
      ..._prismicGeneral
    }
    homepage: prismicHomepage(lang: { eq: $locale }) {
      _previewable
      data {
        projects {
          project {
            isBroken
            document {
              ... on PrismicProjects {
                uid
                data {
                  ...projectCard
                }
              }
            }
          }
        }
      }
    }
  }
`

class PageHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bodyClass: "loading",
      //bodyClass: '',
      mainClass: "",
      index: 0,
    }
    this._onIntroStart = this._onIntroStart.bind(this)
    this._onIntroEnd = this._onIntroEnd.bind(this)
    this._handleMove = this._handleMove.bind(this)
  }

  componentWillUnmount() {
    // PubSub.unsubscribe('INTRO_START', this._onIntroStart)
    // PubSub.unsubscribe('INTRO_END', this._onIntroEnd)
    document.removeEventListener("mousemove", this._handleMove)
  }

  componentWillMount() {}

  componentDidMount() {
    // PubSub.subscribe('INTRO_START', this._onIntroStart)
    // PubSub.subscribe('INTRO_END', this._onIntroEnd)
    this._handleIntro()
  }

  _handleIntro() {
    if ("ontouchstart" in window) {
      this.setState({
        bodyClass: "reveal",
      })
      return
    }

    const _seenIntro = cookie.load("_seenIntro")
    // //const _seenIntro = undefined
    // //console.log(_seenIntro)
    if (!_seenIntro && typeof document !== "undefined") {
      const { notions } = this.props.data.general.data
      const total = notions.length

      document.addEventListener("mousemove", this._handleMove)

      this.timerStart = setTimeout(() => {
        this.setState({
          _seenIntro: false,
          bodyClass: "is-intro",
          total: total,
        })

        PubSub.publish("INTRO_START")

        this.timerEnd = setInterval(() => {
          if (this.state.index + 1 < notions.length) {
            this.setState({
              index: this.state.index + 1,
            })
          } else {
            this._onIntroEnd()
          }
        }, 1000)
      }, 2000)

      var date = new Date()
      var minutes = 30
      date.setTime(date.getTime() + minutes * 60 * 1000)

      cookie.save("_seenIntro", true, {
        path: "/",
        expires: date,
      })
    } else {
      this.setState({
        _seenIntro: true,
        bodyClass: "reveal",
      })
    }
  }

  _handleMove() {
    document.removeEventListener("mousemove", this._handleMove)
    clearInterval(this.timerEnd)
    clearTimeout(this.timerStart)
    //clearTimeout(this.timerEnd)
    this._onIntroEnd()
  }

  _onIntroStart() {
    this.setState({
      bodyClass: "is-intro",
    })
  }
  _onNotion(e, d) {}
  _onIntroEnd() {
    PubSub.publish("INTRO_END")
    this.setState({
      bodyClass: "reveal",
    })
  }

  render() {
    const { bodyClass, index, total } = this.state
    const { general, homepage } = this.props.data
    const { locale } = this.props.pageContext
    //console.log(general.data.site_description.text)
    // console.log(homepage)

    return (
      <>
        <Helmet>
          <body className={"template-home " + bodyClass} />
        </Helmet>

        <div className="page-home">
          <Seo
            title={general.data.site_title.text}
            desc={general.data.site_description.text}
            // pathname={location.pathname}
            locale={locale}
          />

          <HomeGrid data={homepage.data} />
          <ThreeWrapper
            src={"/3d/v4/Rock_04_V1.obj"}
            mtl={"/3d/v4/Rock_04_V1.mtl"}
            texPath="/3d/v4/"
          />
        </div>

        {/* {bodyClass === "is-intro" && <Star total={total} index={index} />} */}
      </>
    )
  }
}

export default withPrismicPreview(PageHome)
