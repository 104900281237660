import React, { useEffect, useMemo, useRef } from "react"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import Marquee from "react-fast-marquee"

const NewsMarquee = ({ lang }) => {
  const ref = useRef()
  const { generalFr, generalEn } = useSiteMetadata()
  const general = useMemo(() => {
    return lang === "fr-fr" ? generalFr.data : generalEn?.data
  }, [lang, generalFr, generalEn])

  useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current) ref.current.style.opacity = 1
    }, 360)
  }, [])

  return (
    <div className="news-marquee fS" ref={ref}>
      <Marquee
        gradient={true}
        speed={40}
        play={true}
        pauseOnHover={true}
        className=""
        gradientWidth={50}
        gradientColor={[199, 199, 190]}
      >
        {general &&
          general?.news &&
          general?.news.map(({ item }, i) => (
            <div key={i} className="news--item px-md">
              <div
                key={i}
                className="texte"
                dangerouslySetInnerHTML={{
                  __html: item.html,
                }}
              />
            </div>
          ))}
      </Marquee>
    </div>
  )
}

export default NewsMarquee
