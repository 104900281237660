export function fileNameByUrl(url) {
  let decoded = decodeURIComponent(url)
  const urlClean = decoded.indexOf("?") > -1 ? decoded.split("?")[0] : decoded
  return urlClean.substring(urlClean.lastIndexOf("/") + 1)
}

export const _linkResolver = (doc) => {
  // console.log(doc)
  switch (doc.type) {
    case "homepage":
      return "/"

    case "projects":
      const locale = doc.lang === "fr-fr" ? "/fr" : ""
      return `${locale}/project/${doc.uid}`

    default:
      if (doc.uid) {
        return `/${doc.uid}`
      }

      return `/`
  }
}

export const _excerptify = (raw) => {
  return "xxx"
}
