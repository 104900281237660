import React, { Component } from 'react';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoIsPlaying: false,
        }
        // this._toggle = this._toggle.bind(this)
        // this._hide = this._hide.bind(this)
    }
    componentDidMount(){
        this.refs.video.onloadeddata = () => {
            //console.log("loaded")
        }
    }

    play(){
        //const videoIsPlaying = this.refs.video
    }

    render() {
        const {url,image} = this.props
        return (
            <div className="video-wrapper">
                <video
                poster={image}
                loop
                autoPlay
                muted
                controls={false}
                playsInline
                ref="video"
                >
                    <source src={url} type="video/mp4"></source>
                </video>
            </div>
        );
    }
}

export default Video;