module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fastclick/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ferriermarchettistudio","accessToken":"MC5YTXNhNUJBQUFQYnczSElF.77-9DAPvv73vv73vv73vv73vv73vv705D--_ve-_ve-_ve-_vXjvv70F77-977-9Ie-_vXTvv73vv73vv73vv73vv707NzHvv70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143628525-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ferrier Marchetti Studio","short_name":"Ferrier Marchetti Sensual City Studio","description":"desc here","start_url":"/","background_color":"#c7c7be","theme_color":"#3D63AE","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6c863819c410702c6afa12daf1baa123"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
