import React, { Component } from "react"
// import { StaticQuery, graphql } from "gatsby"
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { fileNameByUrl } from "../../core/utils"

class ImageFluid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileType: "",
    }
  }

  componentDidMount() {
    //const {data} = this.props
    //console.log(data)
  }

  render() {
    const { data, title } = this.props
    // console.log(this.props)
    return (
      <div>
        {/* <Img fluid={fluid} loading="lazy" /> */}
        <GatsbyImage image={getImage(data)} alt={title} />
      </div>
    )
  }
}

export default ImageFluid

// export default (props) => (
//   <StaticQuery
//     query={graphql`
//       query {
//         allImageSharp {
//           edges {
//             node {
//               fluid(maxWidth: 1280) {
//                 originalName
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={(data) => {
//       const imageName = fileNameByUrl(props.data.url)
//       // console.log(data.allImageSharp.edges)
//       // console.log("imageName",imageName)
//       //const imageName = props.data.url.substring(props.data.url.lastIndexOf('/') + 1)
//       const image = data.allImageSharp.edges.find(
//         //edge => edge.node.fluid.originalName === imageName
//         (edge) => edge.node.fluid.originalName.includes(imageName)
//       )
//       // console.log("image",image)
//       if (!image) {
//         return null
//       }
//       return <ImageFluid fluid={image.node.fluid} />
//     }}
//   />
// )
