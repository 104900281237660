import React, { Component, useMemo } from "react"
import Card from "./home-card"

// class HomeGrid extends Component {
//   render() {
//     const { projects } = this.props.data
//     // console.log(projects)
//     const projectsWithData = projects.filter(({ project }) => {
//       // console.log(project.document)
//       return project.document !== null
//     })
//     // console.log(projectsWithData)

//     return (
//       <div className="grid">
//         {projectsWithData.map(({ project }, index) => (
//           // <Card
//           //   data={project.document?.data}
//           //   slug={project.document.uid}
//           //   key={index}
//           // />
//           <pre key={index}>{JSON.stringify(project)}</pre>
//         ))}
//       </div>
//     )
//   }
// }

// export default HomeGrid

const HomeGrid = ({ data }) => {
  const { projects } = data
  const projectsWithData = useMemo(() => {
    return projects.filter(({ project }) => {
      // console.log(project.document)
      return project.document !== null
      //&& project.isBroken === false
    })
  }, [projects])

  return (
    <div className="grid">
      {projectsWithData.map(({ project }, index) => (
        <Card
          data={project.document?.data}
          slug={project.document.uid}
          key={index}
        />
        // <pre style={{ fontSize: "1rem" }} key={index}>
        //   <div className="title">{project.document.data.title.text}</div>
        //   <div> {JSON.stringify(project.isBroken, false, 2)}</div>
        // </pre>
      ))}
    </div>
  )
}

export default HomeGrid
