import React, { Component } from "react"
//import Img from 'gatsby-image';
import ImageFluid from "./ui/image-fluid"
// import ImageFixedGif from "./ui/image-fixed-gif"
// import ImageFixedSvg from "./ui/image-fixed-svg"
import Video from "./ui/video"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

class ProjectCard extends Component {
  componentDidMount() {}

  render() {
    const { image, column, video_file } = this.props.data
    const { title, desc } = this.props
    const columnFallback = !column ? "3" : column

    const jsonLd = {
      "@context": "http://schema.org",
      "@type": "ImageObject",
      author: "Ferrier Marchetti Studio",
      contentLocation: "",
      contentUrl: image.url,
      datePublished: "",
      description: desc,
      name: title,
    }
    // console.log(image)
    const isJpeg =
      (image.url && image.url.indexOf("jpg") > -1) ||
      image.url?.indexOf("jpeg") > -1
    const isGif = image.url && image.url.indexOf("gif") > -1
    const isSvg = image.url && image.url.indexOf("svg") > -1
    const isVideo = video_file && video_file.url !== null
    // console.log(isGif, image)
    //console.log(isJpeg)
    return (
      <div className={"card col-" + columnFallback + " " + isJpeg}>
        <figure>
          {isJpeg && !isVideo && <ImageFluid data={image} title={title} />}
          {isGif && <img src={image.url} alt={title} />}
          {/* {isGif && <ImageFixedGif data={image} />} */}
          {/* {isSvg && <ImageFixedSvg data={image} />} */}
          {isVideo && <Video url={video_file.url} image={image.url} />}

          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(jsonLd, null, 4),
            }}
          />
        </figure>
      </div>
    )
  }
}

export default ProjectCard
