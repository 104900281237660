import React, { useEffect, useState, useRef } from "react"
import PubSub from "pubsub-js"
import ReactPlayer from "react-player"

// const initialAudioState = false
const AudioPlayer = ({ input, isHome }) => {
  // console.log(input)
  const audioRef = useRef()
  // const [canPlay, setCanPlay] = useState(isHome)
  const [muted, setMuted] = useState(true)
  const [show, setShow] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [timecode, setTimecode] = useState(null)
  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState(0)
  // console.log({ playing })
  useEffect(() => {
    // console.log(isHome)
    if (!isHome) {
      setShow(false)
      setPlaying(false)
    }
  }, [isHome])

  useEffect(() => {
    const tokenP = PubSub.subscribe("AUDIO_PAUSE", () => {})
    const tokenPT = PubSub.subscribe("AUDIO_PLAY_TOGGLE", (e) => {
      console.log(e)
      // setPlaying(!playing)
      setShow(true)
      // _onPlayPause()
      // audioRef.current.play()
    })

    return () => {
      PubSub.unsubscribe(tokenP)
      PubSub.unsubscribe(tokenPT)
    }
  }, [playing, setPlaying])

  useEffect(() => {
    PubSub.publish("AUDIO_PLAYING", playing)
    if (playing) {
      setTimeout(() => {
        setMuted(false)
      }, 150)
    }
  }, [playing])

  // const _onPlayPause = () => setPlaying(!playing)

  const _onReady = () => {
    // console.log("_onReady")
  }

  const _onDuration = () => {
    setDuration(audioRef.current.getDuration())
  }

  const _onProgress = () => {
    // const { currentTime, duration } = audioRef.current
    const currentTime = audioRef.current.getCurrentTime()
    // console.log(currentTime, duration)
    const remaining = duration - currentTime
    setTimecode(_secondsToHms(remaining))

    const progress = (currentTime * 100) / duration
    setProgress(progress)
  }

  const _secondsToHms = (SECONDS) => {
    // return "00:00"
    const d = Number(SECONDS)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    h = String(h).padStart(2, "0")
    m = String(m).padStart(2, "0")
    s = String(s).padStart(2, "0")
    return h > 0
      ? `<span class="hh">${h}</span>` + ":"
      : "" +
          `<span class="mm">${m}</span>` +
          ":" +
          `<span class="ss">${s}</span>`
  }

  // const _className = playing ? "show" : "hide"

  // console.log(input.url)
  return (
    <div className={`audio-player ${show ? "show" : "hide"}`}>
      {show &&
      <ReactPlayer
        ref={audioRef}
        url={input.url}
        playing={playing}
        muted={muted}
        playsinline={true}
        onReady={_onReady}
        onProgress={_onProgress}
        onDuration={_onDuration}
        width={0}
        height={0}
        // controls={true}
      />
    }
      {timecode && (
        <div className="flex">
          <button
            className="sm-only- pr-sm"
            onClick={() => setPlaying(!playing)}
          >
            {playing ? "‖" : "⏵"}
          </button>

          <div
            className="timecode"
            aria-label="play pause"
            tabIndex={0}
            role="button"
            // onClick={() => _onPlayPause()}
            dangerouslySetInnerHTML={{
              __html: timecode,
            }}
          />
        </div>
      )}

      <div
        className="audio-timeline"
        style={{
          width: progress + "%",
        }}
      />
    </div>
  )
}

export default AudioPlayer
