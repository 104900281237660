import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site, generalFr, generalEn } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteUrl
            banner
          }
        }
        generalFr: prismicGeneral(lang: { eq: "fr-fr" }) {
          data {
            site_title {
              text
            }
            site_description {
              text
            }
            portfolio {
              url
            }
            audio {
              url
            }
            news {
              item {
                html
              }
            }
          }
        }
        generalEn: prismicGeneral(lang: { eq: "en-en" }) {
          data {
            site_title {
              text
            }
            site_description {
              text
            }
            portfolio {
              url
            }
            audio {
              url
            }
            news {
              item {
                html
              }
            }
          }
        }
      }
    `
  )
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    generalFr: generalFr,
    generalEn: generalEn,
  }
}
