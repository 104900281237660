import React, { Component } from 'react';
import { StaticQuery,graphql } from "gatsby"
import PubSub from 'pubsub-js';
import Star from './star';

class ScreenSaver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            screenSaverClass: ''
        }
        
        //this._end = debounce(50, this._end.bind(this));
        this._end = this._end.bind(this);
        this._onKey = this._onKey.bind(this);

        this.delayStart = props.data.screensaver_delay * 1000
        this.delay = 1000
        this.timerID = null
    }

    componentWillUnmount() {
        //console.log("ScreenSaver componentWillUnmount")
        clearInterval(this.timerID)
        clearTimeout(this.screenSaverStart)
        clearTimeout(this.screenSaverTick)
        document.removeEventListener('mousemove', this._end);
        window.removeEventListener('scroll', this._end);
        document.removeEventListener('keyup', this._onKey);
        PubSub.unsubscribe('ROUTE_UPDATE', this._end)
    }

    componentDidMount(){
        // console.log("ScreenSaver componentDidMount")
        if ("ontouchstart" in window) {
            
        }else{
            this._init()

            document.addEventListener('keyup', this._onKey)
            PubSub.subscribe('ROUTE_UPDATE', this._end)
        }
    }

    _onKey(e){
        const key = e.key || e.keyCode;
        if(key === "s")this._start()
    }

    _init(){
        // console.log("screensaver _init")
        this.screenSaverStart = setTimeout(() => {
            this._start()
        }, this.delayStart)
    }

    _start(){
        // console.log("screensaver _start")
        this._reset()

        PubSub.publish('SCREENSAVER_START')
        document.body.classList.add("is-screensaver")
        
        document.addEventListener('mousemove', this._end);
        window.addEventListener('scroll', this._end);

        this.screenSaverTick = setTimeout(() => {
            this._notionTick()
        }, this.delayTick)
    }

    _end(){
        //console.log("end")
        document.body.classList.remove("is-screensaver")
        this._reset()

        PubSub.publish('SCREENSAVER_END')
        
        this._init()
    }

    _reset(){
        clearTimeout(this.screenSaverStart)
        clearTimeout(this.screenSaverTick)
        clearInterval(this.timerID)

        document.removeEventListener('mousemove', this._end);
        this.setState({
            index: 0
        })
        //document.querySelectorAll(".screensaver .bar").forEach(el => el.classList.remove("visible"))
    }

    _notionTick(){
        //console.log("_notionTick")
        //this._notionByIndex()
        this.timerID = setInterval(() => {
            this._notionByIndex()
        }, this.delay)
    }

    _notionByIndex(){
        const {
            notions
        } = this.props.data

        const { index } = this.state

        //NEXT
        if(index < notions.length-1){
            this.setState({
                index: index+1
            })
        }else{
            this.setState({
                index: 0
            })
        }

        if(notions[index]){
            //console.log("notionByIndex", notions[index].notion.text)
            PubSub.publish('SCREENSAVER_TICK', {
                notion: notions[index].notion.text,
                index: index
            })
        }
    }

    render() {
        const {
            screenSaverClass,
            index
        } = this.state
        //console.log(index)
        const {
            notions
        } = this.props.data
        //const degreeGap = 180 / notions.length

//console.log(notions)
        return (
            <div className={"screensaver "+screenSaverClass}>
                <Star 
                    total={notions.length}
                    index={index} />
            </div>
        );
    }
}

//export default ScreenSaver;

export default (props) => (
    <StaticQuery
        query={graphql`
            query  {
                general: prismicGeneral {
                    ..._prismicGeneral
                }
            }
        `}
        render={data => (
            <ScreenSaver 
            data={data.general.data} />
        )}
    />
)
